// full-width
.sw-block {
  display: block;
  width: 100%;
}

// custom height
.sw-height-100-vh {
  height: 100vh;
}

.sw-height-100 {
  height: 100%;
}

.sw-m-h-50 {
  min-height: 50px;
}

.sw-min-h-600 {
  min-height: 600px;
}

// custom width
.sw-w-70 {
  width: 70%;
}

.sw-w-600 {
  width: 600px;
}

.sw-width-100 {
  width: 100% !important;
}


