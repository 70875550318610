@import 'styles/variables';

$container-padding: $property-grutter;

%containerPadding {
  padding-left: $container-padding;
  padding-right: $container-padding;
}

.sw-container-fluid {
  @extend %containerPadding;
  height: 100%;
}
