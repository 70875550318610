.sw-subject {
  flex: 1;
  padding: 0 16px;
  &__title {
    font-size: 13px;
    margin-bottom: 3px;
    * {
      font-weight: bold;
    }
  }
  &__subtitle {
    font-size: 11px;
    font-weight: 300;
  }
}
