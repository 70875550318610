@mixin paddingHelper {
  padding-left: $paddings;
  padding-right: $paddings;
}

@mixin paddingVerticalHeading {
  padding-top: $verticalPadding;
  padding-bottom: $verticalPadding;
}

@mixin containerFlexing {
  display: flex;
  flex-direction: column;
}

@mixin componentFlexing {
  flex: 1;
}

@mixin icon {
  fill: $icon-color;
  height: $icon-size;
  width: $icon-size;
}

@mixin respond-to($breakpoint) {
  @media (min-width: map-get($breakpoints, $breakpoint)) {
    @content;
  }
}

