.sw-alert {
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 20px;
  font-size: 13px;
  &--less-margin-bottom {
    margin-bottom: 3px;
  }
  &--warning {
    background-color: #fff3cd;
    border-color: #ffeeba;
    color: #856404;
  }
  &--success {
    background-color: #d4edda;
    border-color: #c3e6cb;
    color: #0B4F00;
  }
  &--error {
    background: #FF6161;
    color: #871B1B;
  }
}
