.c-panel-links {
  display: flex;
  &__icon {
    flex: 0;
    padding: 0 10px;
    & img {
      width: 15px;
      height: 15px;
    }
  }
  &__text {
    flex: 1;
    padding: 0 10px;
    color: white;
    font-size: 13px;
  }
}
