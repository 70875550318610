// padding
.sw-padding-zero {
  padding: 0 !important;
}

.sw-padding-small {
  padding: 4px 8px !important;
}

.sw-padding {
  padding: 8px 16px !important;
}

.sw-padding-large {
  padding: 12px 24px !important;
}

.sw-padding-16 {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.sw-padding-24 {
  padding-top: 24px !important;
  padding-bottom: 24px !important;
}

.sw-padding-32 {
  padding-top: 32px !important;
  padding-bottom: 32px !important;
}

.sw-padding-48 {
  padding-top: 48px !important;
  padding-bottom: 48px !important;
}

.sw-padding-64 {
  padding-top: 64px !important;
  padding-bottom: 64px !important;
}

.sw-padding-25 {
  padding: 25px;
}

.sw-padding-3 {
  padding: 3px;
}

.sw-padding-5 {
  padding: 5px;
}

.sw-padding-b-2 {
  padding-bottom: 2px;
}
.sw-padding-2 {
  padding: 2px;
}

.sw-pl-12 {
  padding-left: 12px;
}