@import 'styles/variables';

.sw-accordion {
  &-block {
    color: map-get($colors, 'white');
    width: 100%;
  }
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &.filter-header {
      min-height: 32px;
      cursor: pointer;
      font-size: 13px;
    }
  }
  &__body {
    margin-bottom: 6px;
  }
  &__expand {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.3s linear;
    user-select: none;
    &--is-active {
      transform: rotate(180deg);
    }
  }
}
