.sw-table, .sw-table-all {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  display: table
}

.sw-table-all {
  border: 1px solid #ccc
}

.sw-table-bordered tr,
.sw-table-all tr {
  border-bottom: 1px solid #ddd
}

.sw-table-striped tbody tr:nth-child(even) {
  background-color: #f1f1f1
}

.sw-table-all tr:nth-child(odd) {
  background-color: #fff
}

.sw-table-all tr:nth-child(even) {
  background-color: #f1f1f1
}

.sw-table-hoverable tbody tr:hover {
  background-color: #ccc
}

.sw-table-centered tr th,
.sw-table-centered tr td {
  text-align: center
}

.sw-table td,
.sw-table th,
.sw-table-all td,
.sw-table-all th {
  padding: 8px 8px;
  display: table-cell;
  text-align: left;
  vertical-align: top
}

.sw-table th:first-child,
.sw-table td:first-child,
.sw-table-all th:first-child,
.sw-table-all td:first-child {
  padding-left: 16px
}

