@import 'styles/variables';

// left and right align
.sw-left {
  float: left !important;
}

.sw-right {
  float: right !important;
}

// disabled state
.sw-disabled {
  cursor: not-allowed;
  opacity: 0.3 !important;
}

.sw-ripple {
  transition: opacity 0s;

  &:active {
    opacity: 0.5;
  }
}

.sw-cursor-pointer {
  cursor: pointer;
}

.sw-flex-nowrap {
  flex-wrap: nowrap;
}

.flexed-center {
  display: flex;
  align-items: center;
}

.sw-item--fixed {
  position: absolute;
  top: 12px;
  right: 0;
}

.sw-item--fixed-right {
  position: absolute;
  right: 13px;
}

.sw-disabled *,
:disabled * {
  pointer-events: none;
}

.sw-fixed {
  max-height: 100vh;
  min-height: 100vh;
  position: fixed;
  height: 100vh;
}

.sw-absolute {
  max-height: 100vh;
  min-height: 100vh;
  position: absolute;
  height: 100vh;
}

.sw-no-gutters {
  padding: unset;
}

.sw-no-f-wrap {
  flex-wrap: nowrap;
}

.justify-end {
  display: flex;
  justify-content: flex-end;
}

.sw-block {
  display: block;
  width: 100%;
}

.sw-width-25 {
  width: 25px;
}

.sw-flex {
  display: flex;
}

.sw-width-fixed {
  width: 400px;
  margin-left: 56px;
}

.sw-profile {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.sw-w-h-20 {
  width: 20px;
  height: 20px;
}

.sw-w-h-24 {
  width: 24px;
  height: 24px;
}

.sw-border-2-light-grey {
  border: 2px solid map-get($colors, 'light-grey');
}

.display-n {
  display: none;
}

.display-i {
  display: inline;
}

.w-100 {
  width: 100%;
}

.text-overflow {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

//fix img in slider
.ril__image,
.ril__imagePrev,
.ril__imageNext {
  object-fit: scale-down;
}

.sw-font-size-10 {
  font-size: 10px;
}

.sw-width-201 {
  width: 201px !important;
}

.sw-width-130 {
  width: 130px !important;
}

.sw-button-delete {
  margin: 0;
  padding: 0;
}

.sw-selectable {
  user-select: text;
}

.auth-lang-btn {
  width: 25px;
  position: absolute;
  top: 15px;
  right: 35px;
  outline: none;

  span {
    outline: none;
  }

  div {
    width: 113px;
  }
}

.dropdown {
  &-style {
    border: none;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.25);

    .dropdown-item {
      padding: 0.7rem 1.5rem;
    }
  }

  &-toggle::after {
    display: none;
  }
}

.filter {
  &-icon {
    width: 1rem;
    height: 1rem
  }

  &-button {
    font-size: 12px;
    color: #27ae60 !important;
    background-color: #42397b;
    border-radius: 5px;
  }

  &-delete {
    color: #e74c3c !important;

  }
}
