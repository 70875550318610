// Variable overrides

// default colors
$colors: (
  'black': black,
  'blue': #3c3374,
  'bright-blue': #2d9cdb,
  'dark-blue': #7669aa,
  'dark-gray': #828282,
  'dark-moderate-blue': #42397b,
  'flamingo': #eb5757,
  'gray': #bdbdbd,
  'green': #27ae60,
  'grey': #7f8c8d,
  'light-blue': #007bff,
  'light-gray': #c4c4c4,
  'light-grayish-blue': #eff1f7,
  'light-grey': #e9e9e9,
  'light-violet': #9f5dc2,
  'lime-green': #55c584,
  'mid-blue': #0056b3,
  'moderate-blue': #519bc5,
  'orange': #e67e22,
  'pure-blue': #00a8ff,
  'purple': #52107d,
  'red': #e74c3c,
  'sea-buckthorn': #f2994a,
  'soft-yellow': #f2c94c,
  'very-dark-blue': #0a0051,
  'very-dark-gray': #333333,
  'very-dark-gray2': #4f4f4f,
  'very-light-gray': #efefef,
  'very-light-gray3': #f2f2f2,
  'violet': #602785,
  'violet-2': #ad6ecc,
  'white': white,
  'yellow': #f5ce00,
  'very-light-gray2': #e0e0e0,
  'very-pale-blue': #e8e7ff,
  'desaturated-dark-blue': #595877,
  'very-dark-desaturated-blue': #323150,
  'dark-grayish-blue': #9392a9,
  'mostly-desaturated-dark-blue': #7169aa,
  'very-soft-blue': #9a8ede,
  'corn-flower-blue': #4b4b72,
  'victoria': #4b4284
);

$primary: #25a8eb;
$success: #55c584;
$error: #eb5757;

$breakpoints: (
  'extra-small': 0,
  'small': 475px,
  'medium': 768px,
  'large': 1200px,
  'extra-large': 1400px
) !default;

$property: 10px;
$paddings: $property;
$margins: $property;
$anti-margins: 0 - $property;
$margin-bottoms: $property * 2;
$anti-margins-bottom: 0 - $property * 2;
$width: 100%;
$verticalPadding: 30px;

$font-size-base: 14px !default; // Assumes the browser default, typically `16px`
$border-width: 1px !default;
$zindex-popover: 1060 !default;
$border-radius-lg: 0.3rem !default;
$font-size-sm: $font-size-base * 0.875 !default;
$headings-color: null !default;
$gray-900: #212529 !default;
$body-color: #fff !default;

$icon-color: white;
$icon-size: 22px;

$property-grutter: 5px;
