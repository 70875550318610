.sw-custom-checkbox {
  display: block;
  position: relative;
  user-select: none;
  cursor: pointer;
  & input {
    display: none;
  }
  & input:checked + &__toogle {
    background-color: #BB6BD9;
    &::after {
      transform: translateX(90%);
    }
  }
  &__toogle{
    width: 25px;
    height: 12.5px;
    transition: all 0.5s;
    background: #DADADA;
    border-radius: 10px;
    position: relative;
    &::after{
      content: '';
      display: block;
      position: absolute;
      left: -1px;
      top: -1px;
      width: 15px;
      height: 15px;
      background: #fff;
      box-shadow: 0px 0px 1px rgba(0, 0, 0, 1);
      border-radius: 50%;
      transition: 0.2s;
    }
  }
  &__padding {
    padding-top: 6px;
  }
}

.c-request-card-img {
  border-radius: 50%;
  height: 35px;
  width: 35px;
  min-width: 35px;
  min-height: 35px;
  max-height: 35px;
  max-width: 35px;
  margin-right: 15px;
}
