.modal {
  &-notification {
    .modal-dialog-centered {
    justify-content: center;
    }
    .modal-content {
      padding: 20px 40px 5px;
      width: auto;
      border: none;
    }
    .modal-header {
      border-bottom: none;
    }
    .btn {
      padding: 7px 35px !important;
    }
  }
}