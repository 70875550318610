@import 'styles/variables';

.sw-form-feedback {
  font-size: 12px;

  display: none;

  &--valid {
    color: map-get($colors, "green");
  }

  &--invalid {
    color: map-get($colors, "red");
  }

  &--validated {
    padding: 5px;
    display: block;
  }
}

