@import 'styles/variables';

$column-padding: $property-grutter;

@mixin columnProperty($column) {
  $result: $column / 12;
  width: $result * 100%;
  position: relative;
}

%columnPadding {
  padding-left: $column-padding;
  padding-right: $column-padding;
}

// EXTRA SMALL
%column-min {
  flex: 0 auto;
}

%column-fluid {
  flex: 1;
  width: 100%;
}

%column-1 {
  @include columnProperty(1);
}

%column-2 {
  @include columnProperty(2);
}

%column-3 {
  @include columnProperty(3);
}

%column-4 {
  @include columnProperty(4);
}

%column-5 {
  @include columnProperty(5);
}

%column-6 {
  @include columnProperty(6);
}

%column-7 {
  @include columnProperty(7);
}

%column-8 {
  @include columnProperty(8);
}

%column-9 {
  @include columnProperty(9);
}

%column-10 {
  @include columnProperty(10);
}

%column-11 {
  @include columnProperty(11);
}

%column-12 {
  @include columnProperty(12);
}

.sw-col-fluid {
  @extend %columnPadding;
  @extend %column-fluid;
}

.sw-col {
  @extend %columnPadding;
  @extend %column-min;
}

.sw-col-1 {
  @extend %columnPadding;
  @extend %column-1;
}

.sw-col-2 {
  @extend %columnPadding;
  @extend %column-2;
}

.sw-col-3 {
  @extend %columnPadding;
  @extend %column-3;
}

.sw-col-4 {
  @extend %columnPadding;
  @extend %column-4;
}

.sw-col-5 {
  @extend %columnPadding;
  @extend %column-5;
}

.sw-col-6 {
  @extend %columnPadding;
  @extend %column-6;
}

.sw-col-7 {
  @extend %columnPadding;
  @extend %column-7;
}

.sw-col-8 {
  @extend %columnPadding;
  @extend %column-8;
}

.sw-col-9 {
  @extend %columnPadding;
  @extend %column-9;
}

.sw-col-10 {
  @extend %columnPadding;
  @extend %column-10;
}

.sw-col-11 {
  @extend %columnPadding;
  @extend %column-11;
}

.sw-col-12 {
  @extend %columnPadding;
  @extend %column-12;
}
