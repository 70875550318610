@import 'styles/variables';

.l-navigation {
  min-width: 221px;
  max-width: 226px;
  padding-top: 45px;
  padding-bottom: 29px;
  overflow-y: auto;
  background: map-get($colors, 'blue');
  color: white;
  &__inner {
    padding: 0 2px;
  }
  &__heading {
    margin-bottom: 14px;
    display: flex;
    padding-bottom: 1px;
    padding-left: 20px;
    padding-right: 5px;
    cursor: pointer;
  }
  &__body {
  }
  &__items {
    &:not(:last-child) {
      margin-bottom: 67px;
    }
  }
  &::-webkit-scrollbar {
    width: 10px;
    background-color: map-get($colors, 'blue');
    padding: 0 2px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 50px;
    border: 2px solid map-get($colors, 'blue');

    background-color: map-get($colors, 'very-light-gray2');
  }
}
