@each $colorKey, $color in $colors {
  $txColor: if($colorKey == 'white', map-get($colors, 'black'), map-get($colors, 'white'));

  .sw-#{$colorKey},
  .sw-hover-#{$colorKey}:hover,
  .sw-focus-#{$colorKey}:focus {
    color: $txColor !important;
    background-color: $color !important;
  }
}
