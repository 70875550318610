@import 'styles/variables';

.react-pagination-js-default{

  user-select: none;
  ul{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .page, .is-active{
    border-radius: 2px;
    width: 17px;
    height: 17px;
    line-height: 17px;
    font-size: 13px;
    text-align: center;
    cursor: pointer;
    margin: 0 7px;
  }
  .page{
    color: map-get($colors, 'dark-gray');
    &:first-child{
      margin-left: 0;
      &::before{
        content: '\e314';
        font-size: 22px;
        font-family: "Material Icons";
        line-height: 21px;
      }
    }
    &:last-child{
      margin-right: 0;
      &::before{
        content: '\e315';
        font-size: 22px;
        font-family: "Material Icons";
        line-height: 21px;
      }
      margin-left: auto;
    }
    &:first-child, &:last-child{
      font-size: 0;
      width: 22px;
      &.disabled{
        pointer-events:none;
        cursor: not-allowed;
        &::before{
          color: map-get($colors, 'very-light-gray2');
        }
      }
    }
  }
  .is-active{
    background: map-get($colors, 'violet-2');
    color: #fff;
    min-width: 17px;
    width: auto;
  }
  .pageElli{
    font-size: 13px;
    letter-spacing: -1px;
    font-weight: 600;
  }
}
.pagination-wrapper{
  background: #fff;
  border-radius: 5px;
  padding: 12px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  .pagintaion-search{
    width: 50px;
    border-width: 1px;
    padding: 0 5px;
    margin: 0 7px;
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}
.pagination-filter{
  padding: 9px 18px;
  background: #fff;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  &__info{
    font-size: 14px;
    line-height: 22px;
    color: map-get($colors, 'dark-gray');
    &:nth-child(2){
      margin-left: 22px;
    }
  }
  &__count{
    color: map-get($colors, 'very-dark-gray');
    margin: 0 4px;
  }
  .pag-select{
    width: 60px;
    margin: 0 5px;
  }
}
