.sw-border {
  border: 1px solid !important;
}

.sw-border-top {
  border-top: 1px solid !important;
}

.sw-border-bottom {
  border-bottom: 1px solid !important;
}

.sw-border-left {
  border-left: 1px solid !important;
}

.sw-border-right {
  border-right: 1px solid !important;
}

// border colors
@each $colorKey, $color in $colors {
  .sw-border-#{$colorKey},
  .sw-hover-border-#{$colorKey}:hover {
    border-color: $color !important;
  }
}

// styles of round
.sw-round-small {
  border-radius: 3px;
}

.sw-round,
.sw-round-medium {
  border-radius: 5px;
}

.sw-round-large {
  border-radius: 10px;
}

.sw-round-xlarge {
  border-radius: 16px;
}

.sw-circle {
  border-radius: 50%;
}

// borders size
.sw-border-zero {
  border: 0 !important;
}
