.sw-thumbnails {
  margin: 0 -5px -10px -5px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  &__modal {
    height: 500px;
    border: 2px solid black;
    box-shadow: 0 5px 10px 5px #c9c9c9;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 0;
    overflow: hidden;
  }
  &__view-img {
    display: block;
    &--portrait {
      height: 100%;
      width: auto;
    }
    &--landscape {
      height: auto;
      width: 100%;
    }
  }
  &__thumb {
    display: inline-flex;
    width: 60px;
    height: 60px;
    margin: 5px;
    position: relative;
    &:hover .sw-form-images__blur {
      display: flex;
    }
  }
  &__inner {
    display: flex;
    min-width: 0;
    overflow: hidden;
    box-shadow: 0 0 2px 1px #c9c9c9;
    transition: all linear 0.1s;
    &:hover {
      box-shadow: 0 0 8px 3px #c9c9c9;
      cursor: pointer;
      transform: scale(1.005);
    }
  }
  &__img {
    display: block;
    width: auto;
    height: 100%;
  }
  &__blur {
    position: absolute;
    background: rgba(127, 140, 141, 0.59);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: none;
    justify-content: center;
    align-items: center;
    color: #470075;
    & .material-icons {
      cursor: pointer;
    }
  }
  &__arrow {
    position: absolute;
    height: 50px;
    width: 50px;
    top: 50%;
    transform: translate(0, -50%);
    &--rtl {
      right: 0;
      background: red;
    }
    &--ltr {
      left: 0;
      background: green;
    }
  }
}