
.l-header {
  width: 56px;
  padding-top: 29px;
  padding-bottom: 29px;
  background: #0a0051;
  z-index: 10;
  &__inner {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 5px;  
  }
}

