@import 'styles/variables';

.sw-row {
  display: flex;
  flex-wrap: wrap;
  margin-left: 0 - $property-grutter;
  margin-right: 0 - $property-grutter;
}

.sw-row-nowrap {
  display: flex;
  margin-left: 0 - $property-grutter;
  margin-right: 0 - $property-grutter;
}
