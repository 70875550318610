@import 'styles/variables';

// Popover
$popover-font-size: $font-size-sm !default;
$popover-bg: map-get($colors, "white") !default;
$popover-max-width: 276px !default;
$popover-border-width: $border-width !default;
$popover-border-color: rgba(map-get($colors, "black"), .2) !default;
$popover-border-radius: $border-radius-lg !default;
$popover-box-shadow: 0 .25rem .5rem rgba(map-get($colors, "black"), .2) !default;

$popover-header-bg: darken($popover-bg, 3%) !default;
$popover-header-color: $headings-color !default;
$popover-header-padding-y: .5rem !default;
$popover-header-padding-x: .75rem !default;

$popover-body-color: $body-color !default;
$popover-body-padding-y: $popover-header-padding-y !default;
$popover-body-padding-x: $popover-header-padding-x !default;

$popover-arrow-width: 1rem !default;
$popover-arrow-height: .5rem !default;
$popover-arrow-color: $popover-bg !default;

$popover-arrow-outer-color: fade-in($popover-border-color, .05) !default;


.sw-popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: $zindex-popover;
  display: block;
  max-width: $popover-max-width;
  margin-left: 17px !important;
  // Allow breaking very long words so they don't overflow the popover's bounds
  word-wrap: break-word;
  background-color: $popover-bg;
  background-clip: padding-box;
  border: $popover-border-width solid $popover-border-color;
  border-radius: $popover-border-radius;

  .sw-popover-arrow {
    position: absolute;
    display: block;
    width: $popover-arrow-width;
    height: $popover-arrow-height;
    margin: 0 $border-radius-lg;

    &::before,
    &::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid;
    }
  }
}

.sw-popover-top {
  margin-bottom: $popover-arrow-height;

  > .sw-popover-arrow {
    bottom: calc((#{$popover-arrow-height} + #{$popover-border-width}) * -1);

    &::before {
      bottom: 0;
      border-width: $popover-arrow-height ($popover-arrow-width / 2) 0;
      border-top-color: $popover-arrow-outer-color;
    }

    &::after {
      bottom: $popover-border-width;
      border-width: $popover-arrow-height ($popover-arrow-width / 2) 0;
      border-top-color: $popover-arrow-color;
    }
  }
}

.sw-popover-right {
  margin-left: $popover-arrow-height;

  > .sw-popover-arrow {
    left: calc((#{$popover-arrow-height} + #{$popover-border-width}) * -1);
    width: $popover-arrow-height;
    height: $popover-arrow-width;
    margin: $border-radius-lg 0; // make sure the arrow does not touch the popover's rounded corners

    &::before {
      left: 0;
      border-width: ($popover-arrow-width / 2) $popover-arrow-height ($popover-arrow-width / 2) 0;
      border-right-color: $popover-arrow-outer-color;
    }

    &::after {
      left: $popover-border-width;
      border-width: ($popover-arrow-width / 2) $popover-arrow-height ($popover-arrow-width / 2) 0;
      border-right-color: $popover-arrow-color;
    }
  }
}

.sw-popover-bottom {
  margin-top: $popover-arrow-height;

  > .sw-popover-arrow {
    top: calc((#{$popover-arrow-height} + #{$popover-border-width}) * -1);

    &::before {
      top: 0;
      border-width: 0 ($popover-arrow-width / 2) $popover-arrow-height ($popover-arrow-width / 2);
      border-bottom-color: $popover-arrow-outer-color;
    }

    &::after {
      top: $popover-border-width;
      border-width: 0 ($popover-arrow-width / 2) $popover-arrow-height ($popover-arrow-width / 2);
      border-bottom-color: $popover-arrow-color;
    }
  }

  // This will remove the popover-header's border just below the arrow
  .sw-popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: $popover-arrow-width;
    margin-left: -$popover-arrow-width / 2;
    content: "";
    border-bottom: $popover-border-width solid $popover-header-bg;
  }
}

.sw-popover-left {
  margin-right: $popover-arrow-height;

  > .sw-popover-arrow {
    right: calc((#{$popover-arrow-height} + #{$popover-border-width}) * -1);
    width: $popover-arrow-height;
    height: $popover-arrow-width;
    margin: $border-radius-lg 0; // make sure the arrow does not touch the popover's rounded corners

    &::before {
      right: 0;
      border-width: ($popover-arrow-width / 2) 0 ($popover-arrow-width / 2) $popover-arrow-height;
      border-left-color: $popover-arrow-outer-color;
    }

    &::after {
      right: $popover-border-width;
      border-width: ($popover-arrow-width / 2) 0 ($popover-arrow-width / 2) $popover-arrow-height;
      border-left-color: $popover-arrow-color;
    }
  }
}

.sw-popover-auto {
  &[x-placement^="top"] {
    @extend .sw-popover-top;
  }

  &[x-placement^="right"] {
    @extend .sw-popover-right;
  }

  &[x-placement^="bottom"] {
    @extend .sw-popover-bottom;
  }

  &[x-placement^="left"] {
    @extend .sw-popover-left;
  }
}


// Offset the popover to account for the popover arrow
.sw-popover-header {
  padding: $popover-header-padding-y $popover-header-padding-x;
  margin-bottom: 0; // Reset the default from Reboot
  padding: 12px 12px 6px;
  font-size: 13px;
  color: $popover-header-color;
  background-color: $popover-header-bg;
  border-bottom: $popover-border-width solid darken($popover-header-bg, 5%);
  $offset-border-width: calc(#{$border-radius-lg} - #{$popover-border-width});
  border-top-left-radius: $offset-border-width;
  border-top-right-radius: $offset-border-width;

  &:empty {
    display: none;
  }
}

.sw-popover-body {
  padding: 7px;
  color: $popover-body-color;
}
