.cards {
  background-color: white;
  padding: 45px;
  border-radius: 5px;
  box-shadow: 0px 0px 30px rgba(52, 51, 116, 0.15);
  // TODO: убрать !important после полного перехода на bootstrap
  &-header {
    padding: 0!important;
    margin-bottom: 25px!important;
    background-color: inherit!important;
    border: none!important;
  }
  &-footer {
    padding: 0!important;
    margin-bottom: 25px!important;
    background-color: inherit!important;
    border: none!important;
  }
  &-title {
    font-size: 1.28rem;
    font-weight: bold;
    margin: 10px 0;
  }
  &-subtitle {
    margin: 10px 0;
  }
  &-body {
    p {
      font-size: 14px;
      line-height: 1.2;
      margin-bottom: 10px;
      a {
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.sw-card {
  margin: 5px;
  padding: 10px 6px 10px 15px;
  background-color: #FFFFFF;
  border-radius: 5px;
  * {
    user-select: none;
    outline: none;
  }
  &__inner {
    display: flex;
    cursor: pointer;
  }
  &__prime-side {
    flex: 1;
  }
  &__secondary-side {
    flex: 0;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  &__heading {
    margin-bottom: 6px;
    position: relative;
  }
  & a {
    width: 100%;
  }
}

.sw-card-status {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.sw-card-icon {
  list-style: none;
  display: flex;
  align-items: center;
  margin: 0 -10px;
  padding: 4px 0;
}

.sw-card-more-button {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #7f8c8d;
  cursor: pointer;
  & .material-icons {
    font-size: 20px;
  }
  &__dropdown {
    position: absolute;
    top: 20px;
    width: 160px;
    right: 0;
    background: white;
    padding: 5px 10px;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.25);
    z-index: 9999;
  }
  &__dropdown-item {
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    cursor: pointer;
    margin: 0 -10px;
    transition: 0.2s;
  }
  &__dropdown-item:hover {
    background: #7f8c8d;
    color: white;
  }
}
