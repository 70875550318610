@import "variables";

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  line-height: inherit;
}

html,
body,
div,
span,
object,
iframe,
img,
strong,
b,
u,
i,
table,
tbody,
tfoot,
thead,
td,
th,
tr,
form,
label,
legend,
header,
footer,
article,
section,
aside {
  //height: 100%; // breaks icons
  //overflow: hidden; // breaks icons
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  background: transparent;
  line-height: 1;
}

input:focus,
select:focus,
textarea:focus {
  outline: none;
}

table,
td,
th,
tr {
  border-collapse: collapse;
  border-spacing: 0;
}

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  font-family: 'Roboto', arial, helvetica, sans-serif;
  font-weight: normal;
  box-sizing: border-box;
  background: map-get($colors, "light-grayish-blue");
  font-size: $font-size-base;
}

input,
select,
textarea,
button,
pre {
  font-family: 'Roboto', arial, helvetica, sans-serif;
  font-weight: 400;
  box-sizing: border-box;
  color: #222;
}

button,
input[type='button'] {
  cursor: pointer;
}

button {
  outline: none;
  padding: 0;
}

a,
a:focus,
a:hover {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  margin: 0;
  font-weight: normal;
}

h1 {
  font-size: 1.375rem;
}

h2 {
  font-size: 1.125rem;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

pre {
  white-space: pre-wrap; /* Since CSS 2.1 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}

#root {
  height: 100%;
}

.sw-disabled *,
:disabled * {
  pointer-events: none;
}

#viewerBackdrop {
  & > div[class*='content'] > div[class*='header'] {
    justify-content: flex-end;
  }
  img {
    max-width: 80vw !important;
    object-fit: contain
  }
}
