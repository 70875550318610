.sw-filter-container {
  margin-left: 23px;
  margin-right: 16px;
  &__heading {
    display: flex;
    align-items: center;
    margin-bottom: 14px;
  }
  &__title {
    flex: 1;
    font-size: 13px;
    padding: 0 11px;
    padding-bottom: 2px;
  }
  &__body {
    margin-bottom: 10px;
    padding: 0 0;
  }
  &__footer {
    display: flex;
    justify-content: space-between;
  }
  .collection-accordion{
    position: relative;
    z-index: 250;
  }
}
