$violet: #0A0051;
$bg-card: #EFF1F7;

.react-datepicker__input-container{
  background: #F9F9F9;
  border-radius: 5px;
  z-index: 5;

}
.react-datepicker-input{
  color: $violet;
  background: transparent;
  border-radius: 5px;
  font-size: 15px;
  line-height: 18px;
  padding: 5px 16px;
  width: 115px;
  position: relative;
  z-index: 5;
}


.react-datepicker-popper{
  z-index: 500 !important;
  .react-datepicker{
    border: none;
    font-size: 16px;
    border-radius: 5px;
    font-family: 'Roboto', arial, helvetica, sans-serif;
    box-shadow: 0px 4px 30px rgba(60, 51, 116, 0.2);
    width: 335px;

  }

  .react-datepicker__month-container{
    width: 100%;
    max-width: 268px;
    margin: 0 33px;
  }
  .react-datepicker__current-month{
    font-size: 20px;
    margin-bottom: 25px;
  }
  .react-datepicker__day-name{
    color: $violet;
  }
  .react-datepicker__header{
    border: none;
    background: #ffffff;
    padding-top: 35px;
  }
  .react-datepicker__month{
    margin: 0 0 30px;
  }
  .react-datepicker__triangle{
    display: none;
  }
  .react-datepicker__navigation{
    width: 25px;
    height: 25px;
    top: 34px;
    border: none;
    background: transparent 6px 6px/13px  no-repeat;
    &--previous{
      background-image: url(../../../assets/icon/round-arrow_back_ios-24px.svg);
      left: 50px;
    }
    &--next{
      background-image: url(../../../assets/icon/round-arrow_forward_ios-24px.svg);
      right: 50px;
    }
  }
  .react-datepicker__day--selected, .react-datepicker__day:hover, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range {
    border-radius: 50%;
  }
  .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
    width: 30px;
    line-height: 30px;
    margin: 2px;
  }
  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__day--selected:hover,
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__day--keyboard-selected:hover,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__month-text:hover,
  .react-datepicker__month-text--keyboard-selected:hover
  {
    border-radius: 50%;
    background: #6F6A8E;
  }
  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__year-read-view--down-arrow{
    display: block;
    width: 20px;
    height: 20px;
    border: none;
    background: url(../../../assets/icon/round-arrow_forward_ios-24px.svg) 0 0/12px transparent no-repeat;
    transform: rotate(90deg) translate(-5px, 4px);
    margin: 0;
  }
  .react-datepicker__year-read-view--down-arrow::before, .react-datepicker__month-read-view--down-arrow::before, .react-datepicker__month-year-read-view--down-arrow::before{
    border: none;
  }
  .react-datepicker__navigation--years{
    top: 0 !important;
    display: flex;
    flex-direction: row;
    justify-content: center;
    &::after{
      content: '';
      display: block;
      height: 100%;
      width: 20px;
      background: url(../../../assets/icon/round-arrow_forward_ios-24px.svg) center/18px transparent no-repeat !important;
    }
  }
  .react-datepicker__navigation--years-upcoming::after{
    transform: rotate(-90deg);
  }
  .react-datepicker__navigation--years-previous::after{
    transform: rotate(90deg);
  }
}

