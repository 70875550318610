@import 'styles/variables';

.input {
  &-control {
    font-size: 1.14rem;
    background-color: #eff1f7 !important;
    border-radius: 5px !important;
    height: 30px;
    line-height: 1.2;
    padding: 20px 15px 15px !important;
  }
}

.sw-input {
  display: block;
  width: 100%;
  border: 2px solid #e9e9e9;
  transition: 0.2s;
  outline: 0;
  font-size: 1.14rem;
  background-color: #f9f9f9;
  border-radius: 5px;
  line-height: 1.2;
  padding: 20px 15px 15px;
  &__textarea {
    border: 2px solid #e9e9e9;
    resize: none;
  }

  &__checkbox {
    position: absolute;
    left: -20px;
    top: 50%;
    transform: translateY(-50%);
    width: auto;
  }

  &:focus {
    border: 2px solid #470075;
  }

  &[readonly] {
    color: #7e7e7e;
    opacity: 1;
  }

  &--valid {
    border: 2px solid map-get($colors, 'green');
  }

  &--invalid {
    border: 2px solid map-get($colors, 'red');
  }

  // default size
  line-height: 20px;
  padding: 3px 12px;
  font-size: 14px;
  // size of big
  &--bg {
    line-height: 30px;
    padding: 8px 16px;
    font-size: 20px;
  }
  // size of small
  &--sm {
    line-height: 21px;
    padding: 4px 8px;
    font-size: 14px;
  }
  &--auto-size {
    min-height: 35px;
    resize: vertical;
    border: 2px solid #e9e9e9;
  }
}
