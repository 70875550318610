.sw-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  &__icon {
    width: 120px;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: rotating 1s linear infinite;
    &--big {
      width: 100px;
      height: 100px;
    }
    &--medium {
      width: 60px;
      height: 60px;
    }
    &--small {
      width: 35px;
      height: 35px;
    }
    & svg {
      width: 100%;
      height: 100%;
    }
  }
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
