.sw-checkbox {
  display: flex;
  position: relative;
  user-select: none;
  cursor: pointer;

  & input,
  [type='checkbox'] {
    display: none;
  }

  &__check {
    margin-right: 15px;
    border-radius: 2px;
    background: rgba(0, 0, 0, 0.1);
    padding: 2px;
  }

  &__toggle {
    display: inline-block;
    background-color: #bdbdbd;
    min-width: 22px;
    max-width: 22px;
    position: relative;
    height: 12px;
    border-radius: 50px;
    transition: all 0.5s;

    &::after {
      content: '';
      display: block;
      position: absolute;
      height: 12px;
      left: 0;
      width: 12px;

      border-radius: 50%;
      background-color: #ffffff;
      transition: all 0.5s;
    }
  }

  &__circular {
    cursor: pointer;
    border: 1px solid #470075;
    width: 16px;
    height: 16px;
    margin: 0 5px;
    border-radius: 100%;
    position: relative;
  }

  &__disabled {
    color: #7e7e7e;
  }

  &__bordered {
    & .sw-icon {
      border: 1px solid #7e7e7e;
    }
  }

  & .sw-icon {
    width: 15px;
    height: 15px;
    & .material-icons {
      opacity: 0;
      transition: 0.03s;
      top: -2px;
      font-size: 15px;
      left: -2px;
      position: relative
    }
  }
  & input:checked + &__toggle {
    background-color: #BB6BD9;
    &::after {
      transform: translateX(90%);
    }
  }

  & input:checked + .sw-icon {
    & .material-icons {
      opacity: 1;
    }
  }

  & input:checked + &__circular:after {
    content: '';
    display: block;
    background: #470075;
    width: 10px;
    height: 10px;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    border-radius: 100%;
    transition: 0.2s;
    position: absolute;
  }
}
