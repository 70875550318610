.sw-icon {
  & .material-icons {
    font-size: 20px;
  }
  

  &--centered {
    margin: 0 auto;
  }
}
