.test {
  &-mode {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    max-width: 100%;
    width: 100%;
    height: 30px;
    top: 0;
    background-color: rgba(red, 0.5);
    color: white;
    z-index: 0;
    p {
      display: flex;
      font-size: 16px;
    }
  }
}