.filter {
  flex: 1 100%;
  display: block;
  &__header {
    display: flex;
    align-items: center;
  }
  &__body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    padding-left: 37px;
  }
  &__footer {
    display: flex;
  }
}

