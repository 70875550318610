.sw-form {
  padding: 10px 10px 30px;
  margin: 5px;
  margin-top: 10px;
  background-color: #FFFFFF;
  border-radius: 5px;
}

.sw-form-group {
  margin-bottom: 10px;

  label {
    display: inline-flex;
    font-size: 1rem;
    line-height: 20px;
    margin: 0;
    color: #333333;
  }

  &:last-child {
    margin-bottom: 0;
    margin-top: 15px;
  }

  .sw-col-fluid {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

.sw-form-checkbox {
  position: relative;
  display: block;

  &__disabled {
    & .sw-label {
      color: #7E7E7E;
      font-size: 13px;
    }
  }
}
