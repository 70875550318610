.sw-tabs {
  background: #fff;
  &__nav {
    display: flex;
  }
  &__tab {
    background: #fff;
    border: 1px solid transparent;
    border-radius: 3px 3px 0 0;
    cursor: pointer;
    margin-left: 5px;
    padding: 8px 10px;
    transition: all 0.4ms linear;
    &--active {
      border-color: #470075;
      border-bottom-color: white;
      margin-bottom: -1px;
    }
    &:first-child {
      margin-left: 0;
    }
  }
  &__panes {
    border: 1px solid #470075;
    padding: 10px 8px;
  }
  &__pane {
    display: none;
    &--active {
      display: block;
    }
  }
}
