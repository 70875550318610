.sw-label {
  display: inline-flex;
  margin-bottom: 0.5rem;
  margin-right: 10px;
  font-size: 16px;
  border-radius: 3px;
  &--circular {
    border-radius: 50%;
    height: 8px;
    width: 8px;
  }
  &--big {
    font-size: 18px;
  }
}
