// margins
.sw-margin {
  margin: 16px !important;
}

.sw-margin-top {
  margin-top: 16px !important;
}

.sw-margin-bottom {
  margin-bottom: 16px !important;
}

.sw-margin-left {
  margin-left: 16px !important;
}

.sw-margin-right {
  margin-right: 16px !important;
}

.sw-m-r-5 {
  margin-right: 5px;
}

.sw-m-l-10 {
  margin-left: 10px !important;
}

.sw-m-r-l-15 {
  margin-right: 15px;
  margin-left: 15px;
}

.sw-m-h-7 {
  margin: 0 7px;
}

.sw-m-t5-b10 {
  margin: 5px 0 10px 0;
}

.sw-m-b-15 {
  margin-bottom: 15px;
}

.sw-m-0 {
  margin: 0;
}

.sw-m-b-0 {
  margin-bottom: 0;
}

.sw-m-r-5 {
  margin-right: 5px;
}

.sw-m-t-b-10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.sw-m-l-100 {
  margin-left: 100px;
}

.sw-m-b-5 {
  margin-bottom: 5px;
}

.sw-m-l-3 {
  margin-left: 3px;
}

.sw-m-l-5 {
  margin-left: 5px;
}

.sw-m-b-8 {
  margin-bottom: 8px;
}

.sw-m-t-34 {
  margin-top: 34px;
}

.sw-m-t6-l10 {
  margin: 6px 10px 0 0;
}

.sw-m-r-18 {
  margin-right: 18px;
}

.sw-m-t-5 {
  margin-top: 5px;
}

.sw-m-b-3 {
  margin-bottom: 3px;
}

.sw-m-b-5 {
  margin-bottom: 5px;
}

.sw-m-r-75 {
  margin-right: 75px;
}

.sw-m-r-40 {
  margin-right: 40px;
}

.sw-m-t--6 {
  margin-top: -6px;
}

.sw-m-t--12 {
  margin-top: -12px;
}

.sw-m-t--2 {
  margin-top: -2px;
}

.sw-m-l-20 {
  margin-left: 20px;
}

.sw-m-t-27 {
  margin-top: 27px;
}

.sw-m-l-30 {
  margin-left: 30px;
}

.sw-m-t-r-2 {
  margin: 2px 0 0 2px;
}

.sw-m-r-0 {
  margin-right: 0;
}

.sw-m-r-10 {
  margin-right: 10px;
}

.sw-m-t-10 {
  margin-top: 10px;
}

.sw-m-r-8 {
  margin-right: 8px;
}

.sw-m-r--10 {
  margin-right: -10px;
}

.sw-m-b-23 {
  margin-bottom: 23px;
}

.sw-m-t-6 {
  margin-top: 6px;
}

.sw-m-r-25 {
  margin-right: 25px;
}

.sw-m-t-b-66 {
  margin: 66px 0;
}

.sw-m-l-auto {
  margin-left: auto;
}

.sw-mb-10 {
  margin-bottom: 10px;
}
