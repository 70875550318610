.scroll-container {
  max-height: 67vh;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    border-radius: 5px;
    background-color: rgb(230, 230, 230);
  }
  &::-webkit-scrollbar-track {
    width: 5px;
    height: 5px;
    border-radius: 5px;
    background-color: rgb(230, 230, 230);
  }
  &::-webkit-scrollbar-thumb {
    width: 5px;
    height: 5px;
    border-radius: 5px;
    background-color: rgb(85, 169, 235);
  }
}

table {
  background-color: white !important;
}

td,
th {
  text-align: center !important;
  padding: 0 !important;
  margin: 0 !important;
}

.stickyColumn {
  position: sticky;
  left: -1px;
  background-color: white;
  box-shadow: 3px 0 5px 0 rgba(0, 0, 0, 0.1);
  width: 230px !important;
  min-width: 230px !important;
  z-index: 2 !important;
  .user-name {
    max-width: 65%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.table-cell-static-height {
  height: 45px !important;
}

.table {
  &-header,
  &-footer,
  &-subheader,
  &-empty-cell {
    position: sticky;
    background-color: white;
  }
  &-header {
    top: -1px;
    min-width: 150px;
    box-shadow: 0 1px 3px 0 rgba(223, 226, 230, 1);
    z-index: 3;
  }
  &-empty-cell {
    top: -1px;
    z-index: 4 !important;
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.1);
  }
  &-subheader {
    z-index: 3;
    top: 44px;
    min-width: 75px;
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.1);
  }
  &-footer {
    z-index: 1;
    bottom: 0px;
    box-shadow: 0 -3px 5px 0 rgba(0, 0, 0, 0.1);
  }
}