.fill-width {
  max-width: 100%;
  width: 100%;
}

.fill-height {
  height: 100vh;
}

.flex {
  &-column {
    flex-direction: column;
  }
  &-row {
    flex-direction: row;
  }
}

.text {
  &-center {
    text-align: center;
  }
}

.d-flex {
  display: flex!important;
}

.d-block {
  display: block!important;
}

.justify-content-start {

}
.justify-content-end {

}
.justify-content-center {
  justify-content: center;
}

.align-items-start {

}
.align-items-end {

}
.align-items-center {
  align-items: center;
}
.align-self-end {
  align-self: flex-end;
}
.required {
  margin-left: 3px;
  color: #EB5757;
}