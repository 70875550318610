.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltip__text {
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
}

.tooltip .tooltip__text::after {
  content: " ";
  position: absolute;
  border-width: 5px;
  border-style: solid;
}

.tooltip .left {
  top: -5px;
  right: 105%;
}

.tooltip .right {
  top: -5px;
  left: 105%;
}

.tooltip .top {
  width: 120px;
  bottom: 120%;
  left: 50%;
  margin-left: -60px;
}

.tooltip .bottom {
  width: 120px;
  top: 120%;
  left: 50%;
  margin-left: -60px;
}

.tooltip .top::after {
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-color: black transparent transparent transparent;
}

.tooltip .bottom::after {
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-color: transparent transparent black transparent;
}

.tooltip .left::after {
  top: 50%;
  left: 100%;
  margin-top: -5px;
  border-color: transparent transparent transparent black;
}

.tooltip .right::after {
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-color: transparent black transparent transparent;
}
