.logo {
  width: 30px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.p-index {
  padding-top: 29px;

  &__inner {
    padding: 0 22px 0 22px;
  }

  &__wrapper {
    padding: 0 10px 0 10px;
  }

  &__heading {
    margin-bottom: 16px;

    &--flexed {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  &__footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 30px 0 40px;
    flex-wrap: wrap;
  }
}

.t-primary-headings {
  line-height: 1;
  padding: 0 7px;
}

.t-secondary-headings {
  padding-bottom: 16px;
  border-bottom: 1px solid #efefef;
  span {
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
  }
}

.sw-title__slash {
  padding: 0 3px;
}

.sw-procent-finishing {
  font-size: 14px;
  margin: 0 13px;
  padding: 3px 2px 0px 3px;
  background: map-get($colors, 'dark-blue');
  color: map-get($colors, 'white');
  border-radius: 3px;
}

.sw-rank {
  &--green {
    color: map-get($colors, 'green');
  }

  &--orange {
    color: map-get($colors, 'orange');
  }

  &--red {
    color: map-get($colors, 'red');
  }
}

.add-button {
  cursor: pointer;
  background: #D9D3FF;
  border-radius: 0.35rem;

  & .material-icons {
    font-size: 1.7rem;
    transition: 0.2s;
    color: #9a8ede;

    &--rotated {
      transform: rotate(45deg);
    }
  }
}

textarea {
  min-height: 100px;
}

.primary-padding {
  padding-top: 2rem;
}

.primary-heading-margin {
  margin-bottom: 1.14rem;
}

.card {
  margin: 0.35rem;
  border-radius: 5px;
  border: none;
  box-shadow: none;
  &-body {
    padding: 10px 6px 10px 15px;
  }
  &-footer {
    border-top: none;
    background-color: #fff;
  }
  &-subtitle {
    color: #828282;
    font-weight: 400;
    font-size: 12px;
  }
  &-inner {
    cursor: pointer;
  }
  &-sign {
    max-width: 28.5rem;
    background-color: white;
    padding: 37px;
    border-radius: 5px;
    box-shadow: 0px 0px 30px rgba(52, 51, 116, 0.15);
    &-header {
      padding: 0;
      margin-bottom: 25px;
      background-color: inherit;
      border: none;
    }
    &-footer {
      padding: 0!important;
      margin-bottom: 25px!important;
      background-color: inherit!important;
      border: none!important;
    }
  }
  &-new-password {
    padding: 45px;
    box-shadow: 0 0 30px rgba(52,51,116,.15);
    max-width: 30rem;
    margin: 0;
    .card-header {
      padding: 0;
      margin-bottom: 25px;
      background-color: #fff;
      border: none;
    }
    .card-body {
      padding: 1.25rem;
    }
  }
  &-forgot-password {
    padding: 45px;
    box-shadow: 0 0 30px rgba(52,51,116,.15);
    max-width: 34.7rem;
    margin: 0;
    .card-header {
      padding: 0;
      margin-bottom: 25px;
      background-color: #fff;
      border: none;
    }
    .card-body {
      padding: 1.25rem;
    }
  }
  &-dropdown {
    white-space: normal;
    width: 1.5rem;
    height: 1.5rem;
    &-item{
      color: #7f8c8d;
    }
    &-menu {
      transform: translate3d(-110px, 30px, 0px)!important;
    }
  }
  &-form {
    padding: 10px 10px 30px;
    margin: 10px 5px 20px 5px;
    background-color: #fff;
    border-radius: 5px;
    &-heading {
      margin-bottom: 1.14rem;
      padding: 1.14rem 0;
    }
    &-secondary-headings {
      color: #333333;
      padding-bottom: 1.14rem;
      border-bottom: 1px solid #EFEFEF;
      span {
        font-weight: 700;
        font-size: 1.42rem;
        line-height: 2.14rem;
      }
    }
    & input, select, textarea {
      border: 2px solid #e9e9e9;
      background-color: #f9f9f9;
      &:focus {
        border: 2px solid #470075;
        box-shadow: none;
      }
    }
    & .sw-col-fluid {
      padding-left: 0.75rem;
    }
    & .sw-form-group:last-child {
      margin-top: 0;
    }
    & .btn {
      height: 2.28rem;
      padding: 0 auto!important;
    }
  }
  &-request {
    padding: 0.7rem 1.4rem;
    min-height: 9.3rem;
    margin-bottom: 0.35rem!important;
    background: #fff;
    border-radius: 5px;
    .card-title {
      color: #2d9cdb!important
    }
    &__img {
      border-radius: 50%;
      height: 2.5rem;
      width: 2.5rem;
      min-width: 2.5rem;
      min-height: 2.5rem;
      max-height: 2.5rem;
      max-width: 2.5rem;
    }
    &__email {
      white-space: nowrap;
      text-overflow: ellipsis;
      color: #2d9cdb!important;
      &:hover {
        white-space: normal;
      }
    }
    &__group-label {
      color: #2d9cdb;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    &__row {
      height: 2.85rem;
    }
    .dropdown {
      left: 0.71rem;
      &-menu {
        transform: translate3d(-71px, 25px, 0px)!important;
      }
    }
    &__dropdown{
      &-menu {
        background: transparent;
        z-index: 9999;
        padding: 0;
        min-width: auto;
        margin: 0;
      }
    }
    .btn {
      margin: 0!important;
    }
    & .form-group label {
      font-size: 1.14rem;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}
