@import 'styles/variables';

.smooth-dnd-container.vertical > .smooth-dnd-draggable-wrapper,
.smooth-dnd-draggable-wrapper.vertical {
  overflow: unset !important;
}

.smooth-dnd-container{
  max-width: 278px;
  width: 100%;
  margin-left: 2.1rem;
}

.sw-widget {
  margin-bottom: 25px;
  padding: 12px 14px 10px 20px;
  position: relative;
  background: map-get($colors, 'white');
  border-radius: 5px;
  color: #4F4F4F;
  line-height: 20px;
  font-size: 16px;
  &__hidden {
    display: none;
  }

  &__dropdown {
    max-width: calc(100% - 30px);
    left: 15px;
    top: 44px;
    position: absolute;
    padding: 5px 5px;
    background: white;
    z-index: 9999;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
    width: 100%;
  }

  &__dropdown-list {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;

    &-item {
      margin: 0 15px 10px 0;
    }
  }

  &__dropdown-select {
    border: 1px solid dodgerblue;
    width: 15px;
    height: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__dropdown-select-item {
    background: dodgerblue;
    width: 9px;
    height: 9px;
  }

  &__dropdown-label {
    font-size: 13px;
    margin: 0 10px;
    cursor: pointer;
  }


  &__dropdown-submit {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    & .sw-button {
      width: 100%;
      margin: 0;
      padding: 0;
    }
    .cancel-btn{
      color: #828282 !important;
      border: none !important;
    }
  }



  &__drag {
    display: flex;
    align-items: center;
    position: absolute;
    width: 7px;
    background: #B0AEBE;
    height: 100%;
    border-radius: 5px;
    top: 0;
    left: 0;
    cursor: move;

    &-inner {
      height: calc(100% - 44px);
      margin: 0 1px;
      width: 5px;
      background: url(../../assets/icon/drag-indicator.svg) transparent;
    }

    & .material-icons {
      color: white;
      width: 15px;
      height: 15px;
      font-size: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      fill: #8A889A;
    }
  }

  &__heading {
    display: flex;
    align-items: center;
    margin-bottom: 12px;

    &-title {
      font-size: 18px;
      color: #4F4F4F;
      font-weight: 700;
      flex: 1 1;
      line-height: 22px;
      span{
        font-weight: 700;
      }
    }

    &-tools {
      display: flex;
      padding: 0 3px;
      align-items: center;
      margin: 0 -3px;
    }
  }

  &__body {
    position: relative;
  }

  &__more {
    border-top: 1px solid #470075;
    border-bottom: 1px solid #470075;
    display: flex;
    justify-content: center;
    align-items: center;

    & .material-icons {
      display: block;
      font-size: 16px;
      color: #470075;
    }
  }

  &__tools-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 8px;
    height: 8px;
    margin-left: 9px;
    color: #828282;
    @media screen and (min-width: 1367px){
      margin-left: 12px;
    }
    & .material-icons {
      font-size: 11px;
      width: 8px;
      font-weight: bold;
      line-height: 8px;
      @media screen and (min-width: 1367px){
        font-size: 14px;
        width: 14px;
        line-height: 14px;
      }
    }
  }

  &__list {
    .sw-widget__link-wrap:first-child{
      .similar-problem-card{
        padding-top: 0;
      }
    }
    .sw-widget__link-wrap:last-child{
      .similar-problem-card{
        border-bottom: none;
      }
    }
  }
  .sw-rank{
    margin: 0 5px;
  }
  &__dropdown-label{
    margin: 0;
    color: #323150;
    font-size: 16px;
    display: flex;
    line-height: 20px;
    flex-direction: row;
    align-items: center;
    &::before{
      content: '';
      display: block;
      width: 10px;
      height: 10px;
      box-sizing: border-box;
      margin-right: 5px;
      border-radius: 50%;
      border: 1px solid #D8D7F0;
    }
  }
  &__radio{
    display: none;
    &:checked + label::before {
      border: 3.5px solid map-get($colors, "violet-2");
    }
  }

  .pag-count{
    color: map-get($colors, 'very-dark-gray2');
    font-size: 12px;
    margin-left: 9px;
  }
}


.submission-widget-card{
  padding: 0;
  margin: 0 0 10px;
  background: transparent;
  min-height: auto;
  .sw-title{
    padding: 0;
  }
}

.sw-widget-tag__dropdown, .sw-widget-sim-problem__dropdown{
  border-radius: 5px;
  box-shadow: 0px 5px 50px rgba(53, 28, 66, 0.15);
  .sw-form{
    padding: 0;
    margin: 0;
  }
}

.sw-widget-tag__dropdown{
  padding: 15px 18px;
}

.sw-widget-sim-problem__dropdown{
  padding: 20px 18px;
  .sw-form-group{
    margin-bottom: 15px;
  }
}
