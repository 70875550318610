@import 'styles/style';

#root {
  height: 100%;
}

.wrapper {
  &-form {
    background-color: #f3f1ff;
  }
}

.sw-mb {
  margin-bottom: $margin-bottoms;
}

.sw-anti-mb {
  margin-bottom: $anti-margins-bottom;
}

.item {
  background: black;
  padding-top: 40px;
  padding-bottom: 40px;
}

.c-context-menu {
  @include paddingVerticalHeading;
  @include containerFlexing;

  &__inner {
    @include componentFlexing;
    padding: 0 15px;
  }
}

// TODO: Выпилить когда разберемся со стилями
.chat {
  &-page {
    background: #fff;
  }

  &-notification {
    background-color: #e8e7ff;
    border-radius: 5px;

    &_inner {
      display: flex;
      padding: 8px;

      .avatar {
        margin-right: 10px;

        img {
          width: 50px;
          height: 50px;
          border-radius: 100%;
        }
      }

      .info {
        display: flex;
        flex-direction: column;
        justify-content: center;

        p {
          margin: 5px 0;
          padding: 0;

          &.name {
            font-size: 14px;
            color: #0a0051;
          }

          &.message-text {
            font-size: 12px;
            color: #000;
          }
        }
      }
    }
  }
}

img {
  width: 100%;
  display: block;
}

.description {
  &-wrapper {
    min-height: 150px;
    height: auto;
    border: 2px solid #e9e9e9;
    border-radius: 3px;
    width: 100%;

    .rc-md-editor {
      min-height: 350px;
      border: none;
    }
  }

  &-preview {
    position: relative;
    min-height: 150px;
    height: auto;
    padding: 18px;

    &.edit {
      &:after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(#7b7b7b, 0.35);
        border-radius: 1px;
        content: '';
        cursor: pointer;
        opacity: 0;
        transition: all 0.3s;
      }

      &:hover {
        &:after {
          opacity: 1;
        }
      }
    }

    color: #333;

    h1 {
      font-size: 32px;
      padding: 0px;
      border: none;
      font-weight: 700;
      line-height: 1.2;
    }

    h2 {
      font-size: 24px;
      padding: 0px 0;
      border: none;
      font-weight: 700;
      line-height: 1.7;
    }

    h3 {
      font-size: 18px;
      padding: 0px 0;
      line-height: 1.7;
      border: none;
    }

    p {
      font-size: 14px;
      line-height: 1.7;
    }

    a {
      color: #0052d9;
    }

    a:hover {
      text-decoration: none;
    }

    strong {
      font-weight: 700;
    }

    ol,
    ul {
      font-size: 14px;
      line-height: 28px;
      padding-left: 36px;
    }

    li {
      margin-bottom: 8px;
      line-height: 1.7;
    }

    hr {
      margin-top: 20px;
      margin-bottom: 20px;
      border: 0;
      border-top: 1px solid #eee;
    }

    pre {
      display: block;
      background-color: #f5f5f5;
      padding: 20px;
      font-size: 14px;
      line-height: 28px;
      border-radius: 0;
      overflow-x: auto;
      word-break: break-word;
    }

    code {
      background-color: #f5f5f5;
      border-radius: 0;
      padding: 3px 0;
      margin: 0;
      font-size: 14px;
      overflow-x: auto;
      word-break: normal;
    }

    code:after,
    code:before {
      letter-spacing: 0;
    }

    blockquote {
      position: relative;
      margin: 16px 0;
      padding: 5px 8px 5px 30px;
      background: none repeat scroll 0 0 rgba(102, 128, 153, 0.05);
      border: none;
      color: #333;
      border-left: 10px solid #d6dbdf;
    }

    img,
    video {
      max-width: 100%;
      width: auto;
    }

    table {
      font-size: 14px;
      line-height: 1.7;
      max-width: 100%;
      overflow: auto;
      border: 1px solid #f6f6f6;
      border-collapse: collapse;
      border-spacing: 0;
      box-sizing: border-box;
    }

    table td,
    table th {
      word-break: break-all;
      word-wrap: break-word;
      white-space: normal;
    }

    table tr {
      border: 1px solid #efefef;
    }

    table tr:nth-child(2n) {
      background-color: transparent;
    }

    // table td, table th {
    //   min-width: 80px;
    //   max-width: 430px
    // }

    table th {
      text-align: center;
      font-weight: 700;
      border: 1px solid #efefef;
      padding: 10px 6px;
      background-color: #f5f7fa;
      word-break: break-word;
    }

    table td {
      border: 1px solid #efefef;
      text-align: left;
      padding: 10px 15px;
      word-break: break-word;
      min-width: 60px;
    }
  }
}
