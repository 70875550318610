.sw-modal {
  position: absolute;
  min-height: 100%;
  width: 100%;
  overflow-y: auto;
  z-index: 999;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  // &__button {
  //   position: absolute;
  //   right: 16px;
  //   top: 16px;
  // }
  &__content {
    position: relative;
    background: #fff;
    padding: 15px 40px 5px;
    border-radius: 5px;
    box-shadow: 0px 0px 30px rgba(60, 51, 116, 0.15);
    max-width: 90%;
    max-height: 95%;
  }
  &__header {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
  }
  &__body {
    margin-bottom: 20px;
  }
  &__footer {
    margin-bottom: 20px;
  }
}

.sw-no-overlay {
  overflow: hidden;
}
