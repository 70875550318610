.sw-list {
  &--flexed {
    display: flex;
    align-items: center;
    & > .sw-list__item {
      width: 15px;
      height: 15px;
      margin-bottom: 0 !important;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 5px;
      margin-left: 5px;
    }
  }
  &__item {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border-radius: 8px;
    &:not(:last-child) {
      margin-bottom: 16px;
    }
    .sw-button{
      min-width: 30px;
    }
  }
  &__label {
    flex: 1;
    font-size: 13px;
    padding: 0 10px 0 16px;
    max-width: 180px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    &--padding {
      padding: 0 46px;
    }
  }
}
