.question-modal {
  width: auto;
  padding: 25px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 30px rgba(60, 51, 116, 0.15);
  position: absolute;
  right: 35px;
  top: 30px;
  z-index: 50;
  white-space: nowrap;

  &-text {
    font-size: 16px;
    line-height: 24px;
  }

  &-header,
  &-body {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 16px;
  }

  .header-btn {
    width: 24px;
    height: 24px;
  }

  p {
    margin-right: 5px;
  }

  .sw-button--send {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 0;

    span {
      line-height: 20px;
      font-size: 13px;
    }
  }
}
