@import 'styles/variables';

// text colors

@each $colorKey, $color in $colors {
  .sw-text-#{$colorKey},
  .sw-hover-text-#{$colorKey}:hover {
    color: $color !important;
  }
}

// sizes
.sw-tiny {
  font-size: 10px !important;
}

.sw-small {
  font-size: 12px !important;
}

.sw-normal {
  font-size: 14px !important;
}

.sw-medium {
  font-size: 16px !important;
}

.sw-large {
  font-size: 18px !important;
}

.sw-xlarge {
  font-size: 24px !important;
}

.sw-fw-bold {
  font-weight: bold;
}

// text align
.sw-text-left {
  text-align: left !important;
}

.sw-text-right {
  text-align: right !important;
}

.sw-text-justify {
  text-align: justify !important;
}

.sw-text-center {
  text-align: center !important;
}

.sw-text-20 {
  font-size: 20px;
}

.sw-text-16 {
  font-size: 16px;
}

.sw-text-inline {
  display: inline-block;
}

.sw-text-13 {
  font-size: 13px;
}

.sw-text-11 {
  font-size: 11px !important;
}

.sw-fz-13 {
  font-size: 13px !important;
}

.sw-fz-11 {
  font-size: 11px !important;
}
