@import 'styles/variables';

.btn {
  padding: 6px 16px !important;
  margin: 5px 0 10px;
  &-primary {
    background-color: $primary !important;
    border: 1px solid $primary !important;
    &.sign {
      background-color: #3c3374 !important;
      border: 1px solid #3c3374 !important;
    }
  }
  &-success {
    background-color: $success !important;
    border: 1px solid $success !important;
  }
  &-error {
    background-color: $error !important;
    border: 1px solid $error !important;
  }
  &-outline-secondary:hover {
    color: #6c757d !important;
    border-color: #6c757d !important;
    background-color: #fff !important;
  }
  &-cancel {
    background-color: transparent !important;
    color: #9392a9 !important;
    border: 1px solid #dddbda !important;
    &:hover {
      background-color: transparent !important;
    }
    &:active {
      background-color: transparent !important;
    }
  }
  &-send {
    background-color: #ad6ecc !important;
    border: 1px solid #ad6ecc !important;
    &:hover {
      background-color: #9e64ba !important;
    }
    &:active {
      background-color: #7e5195 !important;
    }
  }
  &-secondary {
    background-color: #bdbdbd!important;
    border: 1px solid #bdbdbd!important;
  }
  &-filter_delete {
    background: none !important;
    border: none !important;
    color: map-get($colors, 'red') !important;
  }
  &-filter_apply {
    color: map-get($colors, 'green') !important;
    background: map-get($colors, 'dark-moderate-blue') !important;
    border: none !important;
  }
  &:focus {
    box-shadow: none;
  }
}

.sw-button {
  border: none;
  height: 32px;
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  text-decoration: none;
  background-color: inherit;
  text-align: center;
  cursor: pointer;
  white-space: nowrap;
  padding: 6px 16px;
  margin: 5px 0 10px;
  color: white;
  background-position: center;
  transition: background 0.2s;
  &__icon {
    margin: 0;
    padding: 0;
  }
  &__link {
    font-weight: 400;
    color: map-get($colors, 'light-blue');
    padding: 0;
    &:hover,
    &:focus {
      color: map-get($colors, 'mid-blue');
      text-decoration: underline;
    }
  }
  &--send {
    background-color: #ad6ecc;
    &:hover {
      background-color: #9e64ba;
    }
    &:active {
      background-color: #7e5195;
    }
  }
  &--save {
    background-color: #55c584;
    &:hover {
      background-color: #4eb478;
    }
    &:active {
      background-color: #3e9061;
    }
  }
  &--change {
    background-color: #25a8eb;
    &:hover {
      background-color: #2299d6;
    }
    &:active {
      background-color: #1b7bab;
    }
  }
  &--cancel {
    background-color: transparent;
    color: #9392a9;
    border: 1px solid #dddbda;
    &:hover {
      background-color: transparent;
    }
    &:active {
      background-color: transparent;
    }
  }
  &--show_correct_result {
    background-color: #ad6ecc;
    &:hover {
      background-color: #9e64ba;
    }
    &:active {
      background-color: #7e5195;
    }
  }
}

.sw-button {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}