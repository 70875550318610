.sw-tags {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1.5px;
  a{
    line-height: 24px;
    font-size: 15px;
    color: #0A0051 !important;
    border-radius: 5px;
    background: #fff;
    padding: 0 9px;
    margin-right: 10px;
    margin-bottom: 10px;
  }
  &__item {
  }
}
